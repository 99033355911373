import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { PrimaryButton } from './primary-button';
import { InputField } from './input-field';
import { type action } from '~/root';
import Modal from './modal';

type CreateOrgModalProps = {
  onClose: () => void;
};

export const CreateOrgModal = ({ onClose }: CreateOrgModalProps) => {
  const fetcher = useFetcher<typeof action>();
  const loading = fetcher.state !== 'idle';

  const [error, setError] = useState('');
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data != null && fetcher.data?.errors) {
      setError(fetcher.data.errors[0]);
    }

    if (fetcher.state === 'idle' && fetcher.data != null && !fetcher.data?.errors) {
      setError('');
      onClose();
    }
  }, [fetcher.data, fetcher.state, onClose]);

  const handleCreateOrganization = async () => {
    if (orgName === '') {
      return;
    }

    fetcher.submit(
      {
        action: 'create-organization',
        organizationName: orgName,
      },
      {
        method: 'POST',
      },
    );
  };

  return (
    <Modal isOpen title="Create new organization" className="max-w-[600px]" onCancel={onClose}>
      <div className="flex flex-col">
        <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
          To create a new organization, fill in the information below.
        </p>
        <div className="mt-[20px] flex flex-col">
          <InputField
            name="organizationName"
            label="Organization name"
            aria-label="Organization name"
            value={orgName}
            onChange={setOrgName}
            isInvalid={error !== ''}
            errorMessage={error}
            onInput={() => {
              error && setError('');
            }}
          />
          <fetcher.Form
            method="POST"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <PrimaryButton
              type="button"
              isDisabled={orgName === ''}
              loading={loading}
              onPress={handleCreateOrganization}
              className="mt-[20px] px-[16px] py-[8px]"
            >
              Create organization
            </PrimaryButton>
          </fetcher.Form>
        </div>
      </div>
    </Modal>
  );
};
